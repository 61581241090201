import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common/common.service';
@Component({
    selector: 'app-pagenotfound',
    templateUrl: './pagenotfound.component.html',
    styleUrls: ['./pagenotfound.component.scss'],
    standalone: true
})
export class PagenotfoundComponent implements OnInit {

  constructor(
    private commonservice:CommonService
  ) { }

  ngOnInit(): void {
    this.commonservice.main_page_called = false;
  }

}
