<div class="main">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 355">
    <g id="ocean">
      <path id="sky" class="st0" d="M0 0h1000v203.1H0z"/>
      <linearGradient id="water_1_" gradientUnits="userSpaceOnUse" x1="500" y1="354" x2="500" y2="300.667">
        <stop offset="0" stop-color="#fff"/>
        <stop offset="1" stop-color="#95dcec"/>
      </linearGradient>
      <path id="water" fill="url(#water_1_)" d="M0 250.7h1000V354H0z"/>

    </g>
    
    <g id="cloudsAll">
      <path id="cloud1" class="st4" d="M19.5 69.7s-21.3.5-25-12.2c0 0-4.3-21.3 16-21.8 0 0-2.1-12.2 12.2-14.9 0 0 15-3.2 21.3 6.9 0 0 3.6-20.7 17.8-22.3 0 0 24-3 26.6 13.1 0 0 .1 9.5-2.8 13.5 0 0 9.5-15 26.5-4.8 0 0 12.1 7.9 7 20.2 0 0 16 4.8 10.1 18.1 0 0-10.2 8.5-17.1-1.1 0 0-5.5 16-32.5 16 0 0-19.1 2.1-27-13.3 0 0 .5 10.1-13.3 10.6-.1 0-20.3 3.2-19.8-8z"/>
      <path id="cloud3" class="st4" d="M836 132s-18.3 2.1-22.2-4.9c0 0-4.9-11.8 12.5-13.8 0 0-2.5-6.8 9.7-9.6 0 0 12.7-3.1 18.7 2.1 0 0 2-12.2 14-14.3 0 0 16.6-3.3 23.7 2.1 0 0 4.8 3.9 2.4 6.5 0 0 3.1-4.8 18.4-.4 0 0 10.9 3.5 7.2 11 0 0 13.8-1.5 9.7 9.5 0 0-4.1 10.8-15.5 4.8 0 0-3.1 5.6-26.4 7.9 0 0-16.3 2.8-24-5.3 0 0 1 5.7-10.8 7.2-.1.1-17.2 3.6-17.4-2.8z"/>
      <path id="cloud2" class="st4" d="M19.3 159.5s-15.9.6-18.8-5.1c0 0-3.4-9.5 11.7-10.1 0 0-1.7-5.5 9-6.9 0 0 11.2-1.7 16 2.8 0 0 2.5-9.4 13.1-10.3 0 0 17.9-1.8 20 5.4 0 0 .2 4.3-2 6.1 0 0 6.9-6.9 19.8-2.6 0 0 9.1 3.4 5.5 9 0 0 6.5 0 4.5 6.7 0 0-2.6 5.6-9.6 1 0 0-4 7.3-24.2 7.7 0 0-14.2 1.3-20.4-5.5 0 0 .5 4.5-9.8 5 0 .1-15 1.8-14.8-3.2z"/>
      <path id="cloud4" class="st4" d="M370.3 109.5s15.9.6 18.8-5.1c0 0 3.4-9.5-11.7-10.1 0 0 1.7-5.5-9-6.9 0 0-11.2-1.7-16 2.8 0 0-2.5-9.4-13.1-10.3 0 0-17.9-1.8-20 5.4 0 0-.2 4.3 2 6.1 0 0-6.9-6.9-19.8-2.6 0 0-9.1 3.4-5.5 9 0 0-12 1.9-7.7 8 0 0 7.5 4 12.8-.2 0 0 4 7.3 24.2 7.7 0 0 14.2 1.3 20.4-5.5 0 0-.5 4.5 9.8 5 0 0 15.1 1.7 14.8-3.3z"/>
      <path id="cloud5" class="st4" d="M511.7 12.4s-21.3-.3-25 7c0 0-4.3 12.2 16 12.5 0 0-2.1 7 12.2 8.6 0 0 15 1.8 21.3-4 0 0 3.6 11.9 17.8 12.8 0 0 19.5 1.6 27-4.4 0 0 5-4.4 2.1-6.7 0 0 4.1 4.4 21.2-1.5 0 0 12.1-4.6 7-11.6 0 0 16-2.8 10.1-10.4 0 0-10.2-4.9-17.1.6 0 0-5.5-9.2-32.5-9.2 0 0-19.1-1.2-27 7.6 0 0 .5-5.8-13.3-6.1-.1.2-20.3-1.6-19.8 4.8z"/>
    </g>
    <g id="train">

      <g id="car-layers">
        <g x="0px" y="0px"
        viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve" transform="scale(-0.8,0.8) translate(-1000, 9)">
     <g>
       <path style="fill:#D7E6EC;" d="M63.151,312c10.539,0,10.539-8,21.077-8c10.537,0,10.537,8,21.073,8c10.527,0,10.527-8,21.055-8
         c10.534,0,10.534,8,21.069,8c10.547,0,10.547-8,21.093-8c10.535,0,10.535,8,21.07,8c10.547,0,10.547-8,21.094-8
         c10.541,0,10.541,8,21.083,8c10.551,0,10.551-8,21.103-8c10.545,0,10.545,8,21.09,8c10.55,0,10.55-8,21.1-8
         c10.555,0,10.555,8,21.109,8c10.532,0,10.532-8,21.064-8c10.546,0,10.546,8,21.092,8c10.558,0,10.558-8,21.116-8
         c10.559,0,10.559,8,21.119,8c4.831,0,7.451-1.674,9.884-3.49L464,232h-32H48H0l30.676,76.69c2.972-2.201,5.567-4.69,11.432-4.69
         C52.63,304,52.63,312,63.151,312z M32,256L32,256c0-4.418,3.582-8,8-8h368c4.418,0,8,3.582,8,8l0,0c0,4.418-3.582,8-8,8H40
         C35.582,264,32,260.418,32,256z"/>

     
       <path style="fill:#95DCEC;" d="M2.804,349.097c-0.234-0.522-0.456-1.051-0.663-1.587C2.349,348.046,2.57,348.575,2.804,349.097z"/>
       <path style="fill:#95DCEC;" d="M464,336c0,1.104-0.056,2.196-0.165,3.272C463.944,338.196,464,337.105,464,336z"/>
       <path style="fill:#95DCEC;" d="M421.683,304c-5.732,0-8.354,2.355-11.24,4.51c-2.433,1.816-5.054,3.49-9.884,3.49
         c-10.56,0-10.56-8-21.119-8c-10.558,0-10.558,8-21.116,8c-10.546,0-10.546-8-21.092-8c-10.532,0-10.532,8-21.064,8
         c-10.555,0-10.555-8-21.109-8c-10.55,0-10.55,8-21.1,8c-10.545,0-10.545-8-21.09-8c-10.551,0-10.551,8-21.103,8
         c-10.541,0-10.541-8-21.083-8c-10.547,0-10.547,8-21.094,8c-10.535,0-10.535-8-21.07-8c-10.547,0-10.547,8-21.093,8
         c-10.535,0-10.535-8-21.069-8c-10.527,0-10.527,8-21.055,8c-10.537,0-10.537-8-21.073-8c-10.539,0-10.539,8-21.077,8
         c-10.521,0-10.521-8-21.043-8c-5.866,0-8.46,2.489-11.432,4.69c-2.356,1.745-4.95,3.31-9.601,3.31c-10.538,0-10.538-8-21.075-8v16
         c10.538,0,10.538,8,21.075,8c10.517,0,10.517-8,21.033-8c10.521,0,10.521,8,21.043,8c10.539,0,10.539-8,21.077-8
         c10.537,0,10.537,8,21.073,8c10.527,0,10.527-8,21.055-8c10.535,0,10.535,8,21.069,8c10.546,0,10.546-8,21.093-8
         c10.535,0,10.535,8,21.069,8c10.547,0,10.547-8,21.095-8c10.541,0,10.541,8,21.083,8c10.551,0,10.551-8,21.103-8
         c10.545,0,10.545,8,21.089,8c10.55,0,10.55-8,21.101-8c10.555,0,10.555,8,21.109,8c10.532,0,10.532-8,21.064-8
         c10.546,0,10.546,8,21.093,8c10.558,0,10.558-8,21.115-8c10.56,0,10.56,8,21.119,8c10.563,0,10.563-8,21.125-8
         c10.578,0,10.578,8,21.158,8c10.579,0,10.579-8,21.158-8v-16c-10.579,0-10.579,8-21.159,8S432.262,304,421.683,304z"/>
       <path style="fill:#95DCEC;" d="M463.835,339.272c-0.064,0.634-0.153,1.26-0.254,1.883
         C463.682,340.532,463.77,339.906,463.835,339.272z"/>
       <path style="fill:#E2EDF5;" d="M345.578,131.175C339.499,124.082,330.624,120,321.282,120H208c-8.836,0-16,7.163-16,15.999h157.713
         L345.578,131.175z"/>
       <path style="fill:#E2EDF5;" d="M362.943,151.433c-0.912,0.361-1.902,0.566-2.943,0.566h-7.987c-0.004,0-0.009,0.001-0.013,0.001
         s-0.009-0.001-0.013-0.001h-31.973c-0.004,0-0.009,0.001-0.013,0.001s-0.009-0.001-0.013-0.001h-31.973
         c-0.004,0-0.009,0.001-0.013,0.001s-0.009-0.001-0.013-0.001h-31.973c-0.004,0-0.009,0.001-0.013,0.001s-0.009-0.001-0.013-0.001
         h-31.973c-0.004,0-0.009,0.001-0.013,0.001s-0.009-0.001-0.013-0.001h-31.973c-0.004,0-0.009,0.001-0.013,0.001
         s-0.009-0.001-0.013-0.001h-31.973c-0.004,0-0.009,0.001-0.013,0.001s-0.009-0.001-0.013-0.001h-31.973
         c-0.004,0-0.009,0.001-0.013,0.001s-0.009-0.001-0.013-0.001H96.013c-0.004,0-0.009,0.001-0.013,0.001s-0.009-0.001-0.013-0.001H72
         c-0.729,0-1.432-0.106-2.103-0.289L65.455,168h311.688L362.943,151.433z"/>
       <path style="fill:#E2EDF5;" d="M414.894,212.044c-1.39,2.365-3.953,3.956-6.894,3.956h-7.993c-0.002,0-0.005,0-0.007,0
         s-0.005,0-0.007,0h-31.986c-0.002,0-0.005,0-0.007,0s-0.005,0-0.007,0h-31.986c-0.002,0-0.005,0-0.007,0s-0.005,0-0.007,0h-31.986
         c-0.002,0-0.005,0-0.007,0s-0.005,0-0.007,0h-31.986c-0.002,0-0.005,0-0.007,0s-0.005,0-0.007,0h-31.986c-0.002,0-0.005,0-0.007,0
         s-0.005,0-0.007,0h-31.986c-0.002,0-0.005,0-0.007,0s-0.005,0-0.007,0h-31.986c-0.002,0-0.005,0-0.007,0s-0.005,0-0.007,0h-31.986
         c-0.002,0-0.005,0-0.007,0s-0.005,0-0.007,0h-31.986c-0.002,0-0.005,0-0.007,0s-0.005,0-0.007,0H80.007c-0.002,0-0.005,0-0.007,0
         s-0.005,0-0.007,0H56c-1.227,0-2.385-0.284-3.424-0.778L48,232h384L414.894,212.044z"/>
       <path style="fill:#E2EDF5;" d="M389.202,182.069c-1.399,1.2-3.214,1.93-5.202,1.93h-7.989c-0.004,0-0.007,0-0.011,0
         s-0.007,0-0.011,0h-31.978c-0.004,0-0.007,0-0.011,0s-0.007,0-0.011,0h-31.978c-0.004,0-0.007,0-0.011,0s-0.007,0-0.011,0h-31.978
         c-0.004,0-0.007,0-0.011,0s-0.007,0-0.011,0h-31.978c-0.004,0-0.007,0-0.011,0s-0.007,0-0.011,0h-31.978c-0.004,0-0.007,0-0.011,0
         s-0.007,0-0.011,0h-31.978c-0.004,0-0.007,0-0.011,0s-0.007,0-0.011,0h-31.978c-0.004,0-0.007,0-0.011,0s-0.007,0-0.011,0h-31.978
         c-0.004,0-0.007,0-0.011,0s-0.007,0-0.011,0H88.011c-0.004,0-0.007,0-0.011,0s-0.007,0-0.011,0H64
         c-0.976,0-1.907-0.184-2.772-0.503L56.727,200h347.844L389.202,182.069z"/>
       <path style="fill:#C8D7DC;" d="M79.993,216c-4.415-0.004-7.993-3.584-7.993-8c0-4.418,3.582-8,8-8s8,3.582,8,8
         c0,4.416-3.578,7.996-7.993,8h31.986c-4.415-0.004-7.993-3.584-7.993-8c0-4.418,3.582-8,8-8s8,3.582,8,8
         c0,4.416-3.578,7.996-7.993,8h31.986c-4.415-0.004-7.993-3.584-7.993-8c0-4.418,3.582-8,8-8s8,3.582,8,8
         c0,4.416-3.578,7.996-7.993,8h31.986c-4.415-0.004-7.993-3.584-7.993-8c0-4.418,3.582-8,8-8s8,3.582,8,8
         c0,4.416-3.578,7.996-7.993,8h31.986c-4.415-0.004-7.993-3.584-7.993-8c0-4.418,3.582-8,8-8s8,3.582,8,8
         c0,4.416-3.578,7.996-7.993,8h31.986c-4.415-0.004-7.993-3.584-7.993-8c0-4.418,3.582-8,8-8s8,3.582,8,8
         c0,4.416-3.578,7.996-7.993,8h31.986c-4.415-0.004-7.993-3.584-7.993-8c0-4.418,3.582-8,8-8s8,3.582,8,8
         c0,4.416-3.578,7.996-7.993,8h31.986c-4.415-0.004-7.993-3.584-7.993-8c0-4.418,3.582-8,8-8s8,3.582,8,8
         c0,4.416-3.578,7.996-7.993,8h31.986c-4.415-0.004-7.993-3.584-7.993-8c0-4.418,3.582-8,8-8s8,3.582,8,8
         c0,4.416-3.578,7.996-7.993,8h31.986c-4.415-0.004-7.993-3.584-7.993-8c0-4.418,3.582-8,8-8s8,3.582,8,8
         c0,4.416-3.578,7.996-7.993,8h31.986c-4.415-0.004-7.993-3.584-7.993-8c0-4.418,3.582-8,8-8s8,3.582,8,8
         c0,4.416-3.578,7.996-7.993,8H408c2.941,0,5.504-1.592,6.894-3.956c0.698-1.188,1.106-2.567,1.106-4.044c0-4.418-3.582-8-8-8
         h-3.429H56.727H56c-4.418,0-8,3.582-8,8c0,3.191,1.873,5.938,4.576,7.222C53.615,215.715,54.773,216,56,216H79.993z"/>
       <path style="fill:#C8D7DC;" d="M416,256L416,256c0-4.418-3.582-8-8-8H40c-4.418,0-8,3.582-8,8l0,0c0,4.418,3.582,8,8,8h368
         C412.418,264,416,260.418,416,256z"/>
       <path style="fill:#C8D7DC;" d="M87.989,184c-4.413-0.006-7.989-3.585-7.989-8c0-4.418,3.582-8,8-8s8,3.582,8,8
         c0,4.415-3.576,7.993-7.989,8h31.978c-4.413-0.006-7.989-3.585-7.989-8c0-4.418,3.582-8,8-8s8,3.582,8,8
         c0,4.415-3.576,7.993-7.989,8h31.978c-4.413-0.006-7.989-3.585-7.989-8c0-4.418,3.582-8,8-8s8,3.582,8,8
         c0,4.415-3.576,7.993-7.989,8h31.978c-4.413-0.006-7.989-3.585-7.989-8c0-4.418,3.582-8,8-8s8,3.582,8,8
         c0,4.415-3.576,7.993-7.989,8h31.978c-4.413-0.006-7.989-3.585-7.989-8c0-4.418,3.582-8,8-8s8,3.582,8,8
         c0,4.415-3.576,7.993-7.989,8h31.978c-4.413-0.006-7.989-3.585-7.989-8c0-4.418,3.582-8,8-8s8,3.582,8,8
         c0,4.415-3.576,7.993-7.989,8h31.978c-4.413-0.006-7.989-3.585-7.989-8c0-4.418,3.582-8,8-8s8,3.582,8,8
         c0,4.415-3.576,7.993-7.989,8h31.978c-4.413-0.006-7.989-3.585-7.989-8c0-4.418,3.582-8,8-8s8,3.582,8,8
         c0,4.415-3.576,7.993-7.989,8h31.978c-4.413-0.006-7.989-3.585-7.989-8c0-4.418,3.582-8,8-8s8,3.582,8,8
         c0,4.415-3.576,7.993-7.989,8h31.978c-4.413-0.006-7.989-3.585-7.989-8c0-4.418,3.582-8,8-8s8,3.582,8,8
         c0,4.415-3.576,7.993-7.989,8H384c1.988,0,3.803-0.73,5.202-1.93c1.71-1.467,2.798-3.64,2.798-6.07c0-4.418-3.582-8-8-8h-6.858
         H65.455H64c-4.418,0-8,3.582-8,8c0,3.442,2.178,6.368,5.228,7.497C62.093,183.816,63.024,184,64,184H87.989z"/>
       <path style="fill:#C8D7DC;" d="M128,136c4.418,0,8,3.582,8,8c0,4.414-3.575,7.992-7.987,7.999h31.973
         C155.575,151.992,152,148.414,152,144c0-4.418,3.582-8,8-8s8,3.582,8,8c0,4.414-3.575,7.992-7.987,7.999h31.973
         C187.575,151.992,184,148.414,184,144c0-4.418,3.582-8,8-8s8,3.582,8,8c0,4.414-3.575,7.992-7.987,7.999h31.973
         C219.575,151.992,216,148.414,216,144c0-4.418,3.582-8,8-8s8,3.582,8,8c0,4.414-3.575,7.992-7.987,7.999h31.973
         C251.575,151.992,248,148.414,248,144c0-4.418,3.582-8,8-8s8,3.582,8,8c0,4.414-3.575,7.992-7.987,7.999h31.973
         C283.575,151.992,280,148.414,280,144c0-4.418,3.582-8,8-8s8,3.582,8,8c0,4.414-3.575,7.992-7.987,7.999h31.973
         C315.575,151.992,312,148.414,312,144c0-4.418,3.582-8,8-8s8,3.582,8,8c0,4.414-3.575,7.992-7.987,7.999h31.973
         C347.575,151.992,344,148.414,344,144c0-4.418,3.582-8,8-8s8,3.582,8,8c0,4.414-3.575,7.992-7.987,7.999H360
         c1.04,0,2.031-0.205,2.943-0.566c2.961-1.173,5.057-4.056,5.057-7.434c0-4.418-3.582-8-8-8h-10.286H192.001H144l0,0.001H128z"/>
       <path style="fill:#C8D7DC;" d="M95.987,151.999C91.575,151.992,88,148.414,88,144c0-4.418,3.582-8,8-8v-0.001H72
         c-4.418,0-8,3.582-8,8c0,3.689,2.501,6.787,5.897,7.711c0.671,0.183,1.374,0.289,2.103,0.289H95.987z"/>
       <path style="fill:#C8D7DC;" d="M120,144c0-4.418,3.582-8,8-8H96c4.418,0,8,3.582,8,8c0,4.414-3.575,7.992-7.987,7.999h31.973
         C123.575,151.992,120,148.414,120,144z"/>
       <path style="fill:#8CAFCD;" d="M88,208c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.416,3.578,7.996,7.993,8c0.002,0,0.005,0,0.007,0
         s0.005,0,0.007,0C84.422,215.996,88,212.416,88,208z"/>
       <path style="fill:#8CAFCD;" d="M120,208c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.416,3.578,7.996,7.993,8c0.002,0,0.005,0,0.007,0
         s0.005,0,0.007,0C116.422,215.996,120,212.416,120,208z"/>
       <path style="fill:#8CAFCD;" d="M152,208c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.416,3.578,7.996,7.993,8c0.002,0,0.005,0,0.007,0
         s0.005,0,0.007,0C148.422,215.996,152,212.416,152,208z"/>
       <path style="fill:#8CAFCD;" d="M184,208c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.416,3.578,7.996,7.993,8c0.002,0,0.005,0,0.007,0
         s0.005,0,0.007,0C180.422,215.996,184,212.416,184,208z"/>
       <path style="fill:#8CAFCD;" d="M216,208c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.416,3.578,7.996,7.993,8c0.002,0,0.005,0,0.007,0
         s0.005,0,0.007,0C212.422,215.996,216,212.416,216,208z"/>
       <path style="fill:#8CAFCD;" d="M248,208c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.416,3.578,7.996,7.993,8c0.002,0,0.005,0,0.007,0
         s0.005,0,0.007,0C244.422,215.996,248,212.416,248,208z"/>
       <path style="fill:#8CAFCD;" d="M280,208c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.416,3.578,7.996,7.993,8c0.002,0,0.005,0,0.007,0
         s0.005,0,0.007,0C276.422,215.996,280,212.416,280,208z"/>
       <path style="fill:#8CAFCD;" d="M312,208c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.416,3.578,7.996,7.993,8c0.002,0,0.005,0,0.007,0
         s0.005,0,0.007,0C308.422,215.996,312,212.416,312,208z"/>
       <path style="fill:#8CAFCD;" d="M344,208c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.416,3.578,7.996,7.993,8c0.002,0,0.005,0,0.007,0
         s0.005,0,0.007,0C340.422,215.996,344,212.416,344,208z"/>
       <path style="fill:#8CAFCD;" d="M376,208c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.416,3.578,7.996,7.993,8c0.002,0,0.005,0,0.007,0
         s0.005,0,0.007,0C372.422,215.996,376,212.416,376,208z"/>
       <path style="fill:#8CAFCD;" d="M408,208c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.416,3.578,7.996,7.993,8c0.002,0,0.005,0,0.007,0
         s0.005,0,0.007,0C404.422,215.996,408,212.416,408,208z"/>
       <path style="fill:#8CAFCD;" d="M96,176c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.415,3.576,7.993,7.989,8c0.004,0,0.007,0,0.011,0
         s0.007,0,0.011,0C92.424,183.993,96,180.415,96,176z"/>
       <path style="fill:#8CAFCD;" d="M128,176c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.415,3.576,7.993,7.989,8c0.004,0,0.007,0,0.011,0
         s0.007,0,0.011,0C124.424,183.993,128,180.415,128,176z"/>
       <path style="fill:#8CAFCD;" d="M160,176c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.415,3.576,7.993,7.989,8c0.004,0,0.007,0,0.011,0
         s0.007,0,0.011,0C156.424,183.993,160,180.415,160,176z"/>
       <path style="fill:#8CAFCD;" d="M192,176c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.415,3.576,7.993,7.989,8c0.004,0,0.007,0,0.011,0
         s0.007,0,0.011,0C188.424,183.993,192,180.415,192,176z"/>
       <path style="fill:#8CAFCD;" d="M224,176c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.415,3.576,7.993,7.989,8c0.004,0,0.007,0,0.011,0
         s0.007,0,0.011,0C220.424,183.993,224,180.415,224,176z"/>
       <path style="fill:#8CAFCD;" d="M256,176c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.415,3.576,7.993,7.989,8c0.004,0,0.007,0,0.011,0
         s0.007,0,0.011,0C252.424,183.993,256,180.415,256,176z"/>
       <path style="fill:#8CAFCD;" d="M288,176c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.415,3.576,7.993,7.989,8c0.004,0,0.007,0,0.011,0
         s0.007,0,0.011,0C284.424,183.993,288,180.415,288,176z"/>
       <path style="fill:#8CAFCD;" d="M320,176c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.415,3.576,7.993,7.989,8c0.004,0,0.007,0,0.011,0
         s0.007,0,0.011,0C316.424,183.993,320,180.415,320,176z"/>
       <path style="fill:#8CAFCD;" d="M352,176c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.415,3.576,7.993,7.989,8c0.004,0,0.007,0,0.011,0
         s0.007,0,0.011,0C348.424,183.993,352,180.415,352,176z"/>
       <path style="fill:#8CAFCD;" d="M384,176c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.415,3.576,7.993,7.989,8c0.004,0,0.007,0,0.011,0
         s0.007,0,0.011,0C380.424,183.993,384,180.415,384,176z"/>
       <path style="fill:#8CAFCD;" d="M104,144c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.414,3.575,7.992,7.987,7.999
         c0.004,0,0.009,0.001,0.013,0.001s0.009-0.001,0.013-0.001C100.425,151.992,104,148.414,104,144z"/>
       <path style="fill:#8CAFCD;" d="M136,144c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.414,3.575,7.992,7.987,7.999
         c0.004,0,0.009,0.001,0.013,0.001s0.009-0.001,0.013-0.001C132.425,151.992,136,148.414,136,144z"/>
       <path style="fill:#8CAFCD;" d="M168,144c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.414,3.575,7.992,7.987,7.999
         c0.004,0,0.009,0.001,0.013,0.001s0.009-0.001,0.013-0.001C164.425,151.992,168,148.414,168,144z"/>
       <path style="fill:#8CAFCD;" d="M200,144c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.414,3.575,7.992,7.987,7.999
         c0.004,0,0.009,0.001,0.013,0.001s0.009-0.001,0.013-0.001C196.425,151.992,200,148.414,200,144z"/>
       <path style="fill:#8CAFCD;" d="M232,144c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.414,3.575,7.992,7.987,7.999
         c0.004,0,0.009,0.001,0.013,0.001s0.009-0.001,0.013-0.001C228.425,151.992,232,148.414,232,144z"/>
       <path style="fill:#8CAFCD;" d="M264,144c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.414,3.575,7.992,7.987,7.999
         c0.004,0,0.009,0.001,0.013,0.001s0.009-0.001,0.013-0.001C260.425,151.992,264,148.414,264,144z"/>
       <path style="fill:#8CAFCD;" d="M296,144c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.414,3.575,7.992,7.987,7.999
         c0.004,0,0.009,0.001,0.013,0.001s0.009-0.001,0.013-0.001C292.425,151.992,296,148.414,296,144z"/>
       <path style="fill:#8CAFCD;" d="M328,144c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.414,3.575,7.992,7.987,7.999
         c0.004,0,0.009,0.001,0.013,0.001s0.009-0.001,0.013-0.001C324.425,151.992,328,148.414,328,144z"/>
       <path style="fill:#8CAFCD;" d="M360,144c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,4.414,3.575,7.992,7.987,7.999
         c0.004,0,0.009,0.001,0.013,0.001s0.009-0.001,0.013-0.001C356.425,151.992,360,148.414,360,144z"/>
       <path style="fill:#E2EDF5;" d="M128,136h16l0-0.001L122.33,99.884c-1.446-2.41-4.05-3.884-6.86-3.884H104c-4.418,0-8,3.582-8,8
         v31.999V136H128z"/>
         <g id="Text" transform="scale(-0.8,0.8) translate(-1000, 9)">
          <text transform="translate(840.037 150.025)" class="st8 st10" font-size="21">
            404
          </text>
          <text transform="translate(659.5 353.994)" class="st8 st10" font-size="24.025">
          Page not found.
          </text>
        </g>
     </g>
     
    </g>
     
        
      </g>
    </g>
  </svg>
    </div>
  </div>